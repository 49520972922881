export const MAP_CENTER = [44.856214, 38.006471];

export const ymapSettings = {
  // eslint-disable-next-line no-undef
  apiKey: process.env.VUE_APP_YANDEX_MAPS,
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1",
};

export const markerIcon = {
  layout: "default#imageWithContent",
  imageHref: "",
  imageOffset: [-14, -28],
  imageSize: [28, 28],
  contentLayout:
    '<div class="marker">' +
    '      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    "        <path" +
    '          d="M24.5 11.4545C24.5 15.5057 21.8609 19.3978 19.0254 22.379C17.6283 23.8479 16.2278 25.0494 15.1755 25.8841C14.6999 26.2613 14.2971 26.5623 14 26.7778C13.7029 26.5623 13.3001 26.2613 12.8245 25.8841C11.7722 25.0494 10.3717 23.8479 8.97459 22.379C6.13906 19.3978 3.5 15.5057 3.5 11.4545C3.5 5.68439 8.19727 1 14 1C19.8027 1 24.5 5.68439 24.5 11.4545Z"' +
    '          fill="#4CC0CC" stroke="#4CC0CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />' +
    '        <path d="M13 14H15V8H9V14H10.5" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />' +
    "        <path" +
    '          d="M18 14H19V12.33C19.0002 12.0673 18.9486 11.8071 18.8483 11.5643C18.7479 11.3215 18.6007 11.1009 18.415 10.915L17.5 10H15"' +
    '          stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"' +
    "        />" +
    "        <path" +
    '          d="M15 14H15.5" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"' +
    "        />" +
    "        <path" +
    '          d="M11.75 15.5C12.4404 15.5 13 14.9404 13 14.25C13 13.5596 12.4404 13 11.75 13C11.0596 13 10.5 13.5596 10.5 14.25C10.5 14.9404 11.0596 15.5 11.75 15.5Z"' +
    '          stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"' +
    "        />" +
    "        <path" +
    '          d="M16.75 15.5C17.4404 15.5 18 14.9404 18 14.25C18 13.5596 17.4404 13 16.75 13C16.0596 13 15.5 13.5596 15.5 14.25C15.5 14.9404 16.0596 15.5 16.75 15.5Z"' +
    '          stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"' +
    "        />" +
    "      </svg>",
};

export const markerIconActive = {
  layout: "default#imageWithContent",
  imageHref: "",
  imageOffset: [-14, -28],
  imageSize: [105, 28],
  contentLayout:
    '<div class="marker mod-active">' +
    '      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    "        <path" +
    '          d="M24.5 11.4545C24.5 15.5057 21.8609 19.3978 19.0254 22.379C17.6283 23.8479 16.2278 25.0494 15.1755 25.8841C14.6999 26.2613 14.2971 26.5623 14 26.7778C13.7029 26.5623 13.3001 26.2613 12.8245 25.8841C11.7722 25.0494 10.3717 23.8479 8.97459 22.379C6.13906 19.3978 3.5 15.5057 3.5 11.4545C3.5 5.68439 8.19727 1 14 1C19.8027 1 24.5 5.68439 24.5 11.4545Z"' +
    '          fill="#5798A1" stroke="#5798A1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />' +
    '        <path d="M13 14H15V8H9V14H10.5" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />' +
    "        <path" +
    '          d="M18 14H19V12.33C19.0002 12.0673 18.9486 11.8071 18.8483 11.5643C18.7479 11.3215 18.6007 11.1009 18.415 10.915L17.5 10H15"' +
    '          stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"' +
    "        />" +
    "        <path" +
    '          d="M15 14H15.5" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"' +
    "        />" +
    "        <path" +
    '          d="M11.75 15.5C12.4404 15.5 13 14.9404 13 14.25C13 13.5596 12.4404 13 11.75 13C11.0596 13 10.5 13.5596 10.5 14.25C10.5 14.9404 11.0596 15.5 11.75 15.5Z"' +
    '          stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"' +
    "        />" +
    "        <path" +
    '          d="M16.75 15.5C17.4404 15.5 18 14.9404 18 14.25C18 13.5596 17.4404 13 16.75 13C16.0596 13 15.5 13.5596 15.5 14.25C15.5 14.9404 16.0596 15.5 16.75 15.5Z"' +
    '          stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"' +
    "        />" +
    "      </svg>" +
    '      <div class="marker__title">$[properties.iconContent]</div>',
};

export const clusterBidsOptions = {
  cluster: {
    gridSize: 32,
    hasHint: false,
    clusterZIndexHover: 700,
    clusterDisableClickZoom: true,
    clusterOpenBalloonOnClick: false,
    clusterIconContentLayout:
      '<div class="cluster">' +
      '<div class="cluster__content">$[properties.iconContent]</div>' +
      "</div>",
    clusterIcons: [
      {
        href: "",
        size: [44, 44],
        offset: [-22, -44],
        contentOffset: [0, 0],
      },
    ],
  },
};
