<template>
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1871 9.82887V11.5413C13.1878 11.7002 13.1552 11.8576 13.0914 12.0032C13.0275 12.1489 12.934 12.2796 12.8166 12.3871C12.6992 12.4946 12.5606 12.5764 12.4097 12.6273C12.2589 12.6783 12.099 12.6972 11.9403 12.6829C10.1804 12.492 8.48987 11.8918 7.00456 10.9305C5.62268 10.0541 4.45108 8.88487 3.57297 7.50572C2.6064 6.01662 2.00487 4.32123 1.81714 2.5569C1.80285 2.39905 1.82165 2.23997 1.87233 2.08977C1.92302 1.93957 2.00449 1.80155 2.11155 1.6845C2.21862 1.56745 2.34893 1.47393 2.49419 1.40989C2.63945 1.34585 2.79648 1.3127 2.95529 1.31256H4.67108C4.94864 1.30983 5.21773 1.40792 5.42818 1.58856C5.63864 1.76919 5.7761 2.02003 5.81495 2.29433C5.88737 2.84233 6.02167 3.3804 6.2153 3.89827C6.29225 4.10258 6.3089 4.32461 6.26329 4.53807C6.21767 4.75153 6.1117 4.94746 5.95793 5.10266L5.23158 5.82757C6.04575 7.25659 7.23131 8.4398 8.66317 9.25237L9.38952 8.52745C9.54502 8.37398 9.74135 8.26822 9.95523 8.2227C10.1691 8.17717 10.3916 8.19379 10.5963 8.27059C11.1152 8.46384 11.6543 8.59788 12.2034 8.67015C12.4812 8.70927 12.735 8.84893 12.9164 9.06257C13.0977 9.27622 13.1941 9.54894 13.1871 9.82887Z"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "Call2Icon",
};
</script>

<style scoped></style>
