<template>
  <section :class="['glonassButton', styles]" @click.stop="$emit('clicked')">
    <div class="font2-12-16 glonassButton__text">{{ text }}</div>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="glonassButton__icon"
    >
      <path
        d="M3.96974 1.22691C3.82914 1.36756 3.75015 1.55829 3.75015 1.75716C3.75015 1.95604 3.82914 2.14677 3.96974 2.28741L7.68224 5.99991L3.96974 9.71241C3.83312 9.85387 3.75753 10.0433 3.75924 10.24C3.76095 10.4366 3.83982 10.6247 3.97888 10.7638C4.11793 10.9028 4.30604 10.9817 4.50269 10.9834C4.69934 10.9851 4.88879 10.9095 5.03024 10.7729L9.27299 6.53016C9.4136 6.38952 9.49258 6.19879 9.49258 5.99991C9.49258 5.80104 9.4136 5.61031 9.27299 5.46966L5.03024 1.22691C4.8896 1.08631 4.69887 1.00732 4.49999 1.00732C4.30112 1.00732 4.11039 1.08631 3.96974 1.22691Z"
      />
    </svg>
  </section>
</template>
<script>
export default {
  name: "GlonassButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    styles: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.glonassButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
  &.map {
    background: $backgroundBlue;
    color: $special;
    .glonassButton__icon {
      fill: $special;
    }
  }
  &.blacklist {
    background: $backgrounDisabled;
    color: $mainBlack;
    .glonassButton__icon {
      fill: $mainBlack;
    }
  }

  &__text {
    font-weight: 700;
  }
}
</style>
