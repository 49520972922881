<template>
  <section class="mobileHeader">
    <div v-if="hasChevron" class="mobileHeader__back" @click="$emit('back')">
      <img src="@/assets/images/chevron-left.svg" alt="back" />
    </div>

    <div class="mobileHeader__title">{{ title }}</div>

    <div v-if="hasRefresh || hasCall" class="mobileHeader__buttons">
      <div
        v-if="hasRefresh"
        class="mobileHeader__buttons-refresh"
        @click="refresh"
      >
        <RefreshIcon />
      </div>

      <div
        v-if="hasCall"
        class="mobileHeader__buttons-call"
        @click="$emit('call')"
      >
        <CallIcon />
      </div>
    </div>
  </section>
</template>

<script>
import RefreshIcon from "@/assets/icons/svg/RefreshIcon.vue";
import CallIcon from "@/assets/icons/svg/CallIcon.vue";
export default {
  name: "MobileHeader",
  components: { RefreshIcon, CallIcon },
  props: {
    title: {
      type: String,
      default: "",
    },
    // для страниц из доп. меню - показать иконку возврата
    hasChevron: {
      type: Boolean,
      default: false,
    },
    // для страниц с принудительным обновлением
    hasRefresh: {
      type: Boolean,
      default: false,
    },
    // для страниц с возможностью звонка
    hasCall: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    refresh() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.mobileHeader {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: #fff;
  z-index: 2;
  &__back {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }
  &__title {
    font-size: 16px;
    font-weight: 700;
    color: $mainBlack;
  }
  &__buttons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
    display: flex;
    align-items: center;
    gap: 20px;

    &-refresh {
      font-family: $fontPrimary;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: $special;
    }

    &-call {
      margin-top: 1px;
    }
  }
}
</style>
