<template>
  <div :class="['truck', { active: isActive }]">
    <template v-if="truck">
      <div class="font1-24-30 truck__title">
        <div class="font1-20-24 truck__title-number">{{ truck.number }}</div>
        <GlonassStatus :title="truck.glonass_status" :styles="status" />
      </div>

      <div class="font2-14-18 truck__subtitle">Последние данные получены</div>

      <TruckGlonassInfo :truck="truck" size="huge" class="truck__info" />

      <div class="truck__balance">
        <div class="font2-12-16 truck__balance-title">Баланс у оператора</div>
        <div class="font2-14-18 truck__balance-value">
          {{ formattedNumberRu(truck.glonass_balance, 2) }}&nbsp;₽
        </div>
      </div>

      <CancelButton
        title="Закрыть"
        class="truck__button"
        @button-clicked="$emit('close')"
      />
    </template>
  </div>
</template>

<script>
import CancelButton from "@/components/UI/controls/buttons/CancelButton.vue";
import TruckGlonassInfo from "@/components/trucks/TruckGlonassInfo.vue";
import GlonassStatus from "@/components/trucks/TruckCard/components/GlonassStatus.vue";
import { glonassStatuses } from "@/components/trucks/TruckCard/components/glonassData";
import { formattedNumberRu } from "@/core";

export default {
  name: "MobileTruckDetail",
  components: { GlonassStatus, TruckGlonassInfo, CancelButton },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    truck: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    status() {
      return Object.keys(glonassStatuses).find(
        (k) => glonassStatuses[k] === this.truck.glonass_status
      );
    },
  },
  methods: {
    formattedNumberRu,
  },
};
</script>

<style lang="scss" scoped>
.truck {
  position: absolute;
  bottom: calc(-216px - 7rem);
  width: 100%;
  padding: 32px 16px calc(32px + 7rem);
  background: #fff;
  border-radius: 20px 20px 0 0;
  z-index: 101;
  transition: bottom 0.3s ease;

  &.active {
    bottom: 0;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &-number {
      font-weight: 500;
      color: $mainBlack;
    }
  }
  &__subtitle {
    margin-bottom: 8px;
    color: $greyText;
  }
  &__info {
    margin-bottom: 24px;
  }
  &__balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 18px;
    padding-top: 12px;
    border-top: 1px solid $backgroundGray;
    &-title {
      font-weight: 500;
      color: $textSecondary;
    }
    &-value {
      font-weight: 700;
      color: $mainBlack;
    }
  }
  &__button {
    width: 100%;
  }
}
</style>
