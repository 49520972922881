import TruckCard from "@/components/trucks/TruckCard/TruckCard.vue";
import EmptyTrucks from "@/components/trucks/EmptyTrucks.vue";
import BlacklistModal from "@/components/UI/modals/BlacklistModal.vue";
import { monthToGenitive, parsedPhone } from "@/core";
import Api from "@/api";
export default {
  components: {
    EmptyTrucks,
    TruckCard,
    BlacklistModal,
  },
  data() {
    return {
      isLoading: false,
      trucksList: [],
      yandexMapList: [],
      yandexMapBounds: [],
      activeTruck: 0,
      isBlacklistOpen: false,
      blacklistDescription: "",
      blacklistPeriod: "",
      glonassPhoneView: "",
      glonassPhoneLink: "",
    };
  },
  async mounted() {
    this.isLoading = true;
    try {
      const { data } = await Api.profile.getUserTrucks();
      this.trucksList = data;

      // готовим массив для карты
      this.trucksList.forEach((item) => {
        if (item.latitude && item.longitude) {
          const { truck_id, number, latitude, longitude } = item;
          this.yandexMapList.push({
            truck_id,
            number,
            coords: [latitude, longitude],
          });
        }
      });
      if (this.yandexMapList.length) {
        // определяем границы карты
        this.calcInitialMapBounds();
      }

      // загружаем телефон Глонасс-диспетчера
      const response = await Api.profile.getGlonassPhone();
      this.glonassPhoneLink = `+${response.data.phone}`;
      this.glonassPhoneView = parsedPhone(this.glonassPhoneLink);
    } catch (e) {
      console.log(e.data);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    calcInitialMapBounds() {
      // определение границ карты
      if (this.yandexMapList.length === 1) {
        // если только одна машина, то хардкодим размеры карты относительно машины
        this.yandexMapBounds = [
          [
            Number(this.yandexMapList[0].coords[0]) - 0.1,
            Number(this.yandexMapList[0].coords[1]) - 0.1,
          ],
          [
            Number(this.yandexMapList[0].coords[0]) + 0.1,
            Number(this.yandexMapList[0].coords[1]) + 0.1,
          ],
        ];
      } else {
        let latitudes = this.yandexMapList.map((item) => item.coords[0]);
        let longitudes = this.yandexMapList.map((item) => item.coords[1]);
        this.yandexMapBounds = [
          [Math.min(...latitudes), Math.min(...longitudes)],
          [Math.max(...latitudes), Math.max(...longitudes)],
        ];
      }
    },
    highlightOnMap(truck) {
      this.activeTruck = Number(truck.truck_id);
      this.yandexMapBounds = [
        [Number(truck.latitude) - 0.001, Number(truck.longitude) - 0.001],
        [Number(truck.latitude) + 0.001, Number(truck.longitude) + 0.001],
      ];
    },
    showBlacklist(truck) {
      this.blacklistDescription = truck.block_reason;
      this.blacklistPeriod = truck.blocked_until
        ? monthToGenitive(truck.blocked_until)
        : "Бессрочно";
      this.isBlacklistOpen = true;
    },
    closeBlacklist() {
      this.isBlacklistOpen = false;
      this.blacklistDescription = "";
      this.blacklistPeriod = "";
    },
  },
};
