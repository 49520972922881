<template>
  <ModalWrapper @close="$emit('close')">
    <div class="content two-button-modal">
      <div class="font1-24-30 content__title">Позвонить оператору</div>

      <div class="font2-16-20 content__description">
        <ul class="content__description-list">
          <li>Если у вас вопросы по привязанным авто</li>
          <li>Если вы хотите удалить или добавить авто</li>
          <li>Если вы хотите пополнить счет ГЛОНАСС</li>
        </ul>
      </div>

      <MainButton
        title="Позвонить"
        class="content__button"
        @button-clicked="$emit('call')"
      >
        <Call2Icon class="content__button-icon" />
      </MainButton>
    </div>
  </ModalWrapper>
</template>

<script>
import ModalWrapper from "@/components/UI/ModalWrapper";
import MainButton from "@/components/UI/controls/buttons/MainButton";
import Call2Icon from "@/assets/icons/svg/Call2Icon.vue";
export default {
  name: "MobileCallModal",
  components: {
    ModalWrapper,
    MainButton,
    Call2Icon,
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 440px;
  @media (max-width: 600px) {
    width: 304px;
  }
  &__title {
    margin-bottom: 8px;
    font-weight: 500;
    color: $mainBlack;
  }
  &__description {
    margin-bottom: 16px;
    font-weight: 500;
    color: $textSecondary;

    &-list li {
      margin-bottom: 8px;
      margin-left: 24px;
      list-style-type: disc;
    }
  }

  &__button.el-button.is-plain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    border-radius: 40px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;

    .content__button-icon {
      margin-bottom: 2px;
    }
  }
}
</style>
