<template>
  <section class="trucks" v-loading="isLoading">
    <div class="font1-18-24 trucks__header">Мои машины</div>

    <div class="trucks__body">
      <template v-if="trucksList.length">
        <div class="trucks__body-aside">
          <div
            :ref="truck.truck_id"
            v-for="truck in trucksList"
            :key="truck.truck_id"
          >
            <TruckCard
              :truck="truck"
              :is-active="truck.truck_id === activeTruck"
              @blacklist="showBlacklist(truck)"
              @highlight="highlightOnMap(truck)"
            />
          </div>

          <div class="support">
            <div class="font2-12-16 support__title">Телефон оператора:</div>
            <div class="support__content">
              <a
                :href="`tel:${glonassPhoneLink}`"
                class="font2-14-18 support__content-phone"
              >
                {{ glonassPhoneView }}
              </a>
              <el-tooltip
                effect="dark"
                placement="top"
                popper-class="support__content-tooltip"
              >
                <div slot="content">
                  <ul class="font2-14-18">
                    <li>Если у вас вопросы по привязанным авто</li>
                    <li>Если вы хотите удалить или добавить авто</li>
                    <li>Если вы хотите пополнить счет ГЛОНАСС</li>
                  </ul>
                </div>
                <QuestionIcon />
              </el-tooltip>
            </div>
          </div>
        </div>

        <div class="trucks__body-map">
          <YandexMapComponent
            v-if="yandexMapBounds.length"
            :points="yandexMapList"
            :bounds="yandexMapBounds"
            :active="activeTruck"
            @item-selected="scrollToTruck"
          />

          <EmptyTrucks
            v-if="!yandexMapBounds.length && !isLoading"
            title="У вас нет машин с позиционированием по ГЛОНАСС"
          />
        </div>
      </template>

      <EmptyTrucks
        v-if="!trucksList.length && !isLoading"
        title="У вас еще не добавлено ни одного автомобиля"
      />
    </div>

    <BlacklistModal
      v-if="isBlacklistOpen"
      :description="blacklistDescription"
      :period="blacklistPeriod"
      @close="closeBlacklist"
    />
  </section>
</template>

<script>
import QuestionIcon from "@/assets/icons/svg/QuestionIcon.vue";
import YandexMapComponent from "@/components/trucks/YandexMap/YandexMapComponent.vue";
import trucksMixin from "@/views/TrucksView/trucksMixin";

export default {
  name: "DesktopTrucksView",
  components: { YandexMapComponent, QuestionIcon },
  mixins: [trucksMixin],
  methods: {
    scrollToTruck(active) {
      this.activeTruck = active;
      let el = this.$refs[active];
      el[0].scrollIntoView({ behavior: "smooth", block: "center" });
    },
  },
};
</script>

<style lang="scss" scoped>
.trucks {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

  &__header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 64px;
    padding-left: 32px;
    border-bottom: 1px solid $backgroundGray;
    background: #fff;
    font-weight: 500;
    color: $mainBlack;
  }

  &__body {
    flex: 1;
    display: flex;
    overflow: auto;

    &-aside {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 400px;
      padding: 16px 16px 80px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: $backgroundGray;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #e0e5e6;
        border-radius: 4px;
      }

      .support {
        position: fixed;
        bottom: 0;
        left: 220px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 400px;
        height: 66px;
        padding: 0 25px 0 16px;
        background: #fff;
        box-shadow: 0 0 10px 0 #8484841c;
        border-top: $backgroundGray;
        z-index: 100;

        &__title {
          font-weight: 500;
          color: $textSecondary;
        }
        &__content {
          display: flex;
          align-items: center;
          gap: 4px;
          &-phone {
            font-weight: 700;
            color: $special;
          }
        }
      }
    }

    &-map {
      flex: 1;
    }
  }
}
</style>
