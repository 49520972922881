<template>
  <section v-loading="isLoading" :class="['trucks', { fixed: mapView }]">
    <MobileHeader
      title="Мои машины"
      :has-chevron="true"
      :has-refresh="true"
      :has-call="true"
      @call="isCallOpen = true"
      @back="$router.push({ name: 'moreMenu' })"
    />
    <MobileTrucksTabs
      :active="mapView"
      class="trucks__tab"
      @change="changeView"
    />
    <div v-if="mapView" class="trucks__content">
      <YandexMapComponent
        v-if="yandexMapBounds.length"
        :points="yandexMapList"
        :bounds="yandexMapBounds"
        :active="activeTruck"
        @item-selected="showDetail"
      />
      <EmptyTrucks
        v-if="!yandexMapBounds.length && !isLoading"
        title="У вас нет машин с позиционированием по ГЛОНАСС"
      />
    </div>
    <div v-else class="trucks__content">
      <div v-if="trucksList.length" class="trucks__content-list">
        <div
          :ref="truck.truck_id"
          v-for="truck in trucksList"
          :key="truck.truck_id"
        >
          <TruckCard
            :truck="truck"
            :is-active="truck.truck_id === activeTruck"
            @blacklist="showBlacklist(truck)"
            @highlight="switchToMap(truck)"
          />
        </div>
      </div>
      <EmptyTrucks
        v-if="!trucksList.length && !isLoading"
        title="У вас еще не добавлено ни одного автомобиля"
      />
    </div>

    <MobileTruckDetail
      v-if="mapView"
      :is-active="isShowDetail"
      :truck="detailTruck"
      @close="closeDetail"
    />

    <MobileCallModal
      v-if="isCallOpen"
      @call="callGlonass"
      @close="isCallOpen = false"
    />

    <BlacklistModal
      v-if="isBlacklistOpen"
      :description="blacklistDescription"
      :period="blacklistPeriod"
      @close="closeBlacklist"
    />
  </section>
</template>

<script>
import MobileHeader from "@/components/mobileHeader/MobileHeader.vue";
import MobileTrucksTabs from "@/components/trucks/MobileTrucksTabs.vue";
import MobileTruckDetail from "@/components/trucks/MobileTruckDetail.vue";
import MobileCallModal from "@/components/UI/modals/MobileCallModal.vue";
import YandexMapComponent from "@/components/trucks/YandexMap/YandexMapComponent.vue";
import trucksMixin from "@/views/TrucksView/trucksMixin";

export default {
  name: "MobileTrucksView",
  components: {
    MobileHeader,
    MobileTrucksTabs,
    MobileTruckDetail,
    MobileCallModal,
    YandexMapComponent,
  },
  mixins: [trucksMixin],
  data() {
    return {
      mapView: 0,
      isShowDetail: false,
      detailTruck: null,
      isCallOpen: false,
    };
  },
  methods: {
    changeView(tab, clearActive = true) {
      // при переходе с карты на список восстанавливаем изначальные границы карты
      // и убираем модалку детализации машины
      if (!tab && this.trucksList.length) {
        this.calcInitialMapBounds();
        this.isShowDetail = false;
        this.detailTruck = null;

        if (this.activeTruck) {
          this.$nextTick(() => {
            let el = this.$refs[this.activeTruck];
            el[0].scrollIntoView({ block: "center" });
          });
        }
        // если переходим на карту не из машинки, а по табу - сбрасываем подветку машины
      } else if (tab && this.trucksList.length && clearActive) {
        this.activeTruck = 0;
      }
      this.mapView = tab;
    },
    switchToMap(truck) {
      this.changeView(1, false);
      this.highlightOnMap(truck);
      this.detailTruck = truck;
      this.isShowDetail = true;
    },
    showDetail(id) {
      this.activeTruck = id;
      this.detailTruck = this.trucksList.find((item) => item.truck_id === id);
      this.isShowDetail = true;
    },
    closeDetail() {
      this.isShowDetail = false;
      this.detailTruck = null;
      this.activeTruck = 0;
    },
    callGlonass() {
      const linkElement = document.createElement("a");
      linkElement.href = `tel:${this.glonassPhoneLink}`;
      document.body.appendChild(linkElement);
      linkElement.click();
      linkElement.remove();
      this.isCallOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.trucks {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  padding: 96px 0 7rem;
  background: $bckgrnd;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100dvh;
  }

  &__tab {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
  }

  &__content {
    flex: 1;

    &-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px 16px 86px;
    }
  }
}
</style>
