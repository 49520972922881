<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 2.55556C4.99312 2.55556 2.55556 4.99312 2.55556 8C2.55556 11.0069 4.99312 13.4444 8 13.4444C11.0069 13.4444 13.4444 11.0069 13.4444 8C13.4444 4.99312 11.0069 2.55556 8 2.55556ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM6.62131 6.23451L6.62236 6.2322L6.62173 6.23353L6.62131 6.23451Z"
      fill="#3D4A5D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 2.55556C4.99312 2.55556 2.55556 4.99312 2.55556 8C2.55556 11.0069 4.99312 13.4444 8 13.4444C11.0069 13.4444 13.4444 11.0069 13.4444 8C13.4444 4.99312 11.0069 2.55556 8 2.55556ZM5.83281 4.64964C6.26085 4.17728 6.97745 3.64013 7.99983 3.64014C9.01653 3.64014 9.81436 4.16025 10.275 4.91587C10.7239 5.65235 10.8444 6.58878 10.6255 7.48526C10.4306 8.28324 9.78404 8.91398 9.3317 9.28702C9.08629 9.48941 8.85231 9.65235 8.68017 9.76463C8.59359 9.8211 8.52133 9.86561 8.46942 9.89674C8.44343 9.91232 8.42244 9.92461 8.4072 9.93342L8.38872 9.94402L8.38286 9.94734L8.3808 9.94849L8.37965 9.94914C8.0047 10.1587 7.53052 10.0249 7.32093 9.64992C7.11152 9.27533 7.24511 8.802 7.61925 8.5921L7.62018 8.59157L7.62871 8.58668C7.63716 8.58179 7.651 8.57371 7.6695 8.56261C7.70658 8.54038 7.76205 8.50627 7.83036 8.46172C7.96802 8.37193 8.15255 8.24316 8.34198 8.08693C8.75934 7.74274 9.04781 7.38864 9.11434 7.11622C9.24618 6.57636 9.15756 6.07137 8.94674 5.72553C8.74759 5.39883 8.43498 5.19569 7.99982 5.19569C7.57033 5.19569 7.24072 5.41254 6.98549 5.69418C6.85926 5.83349 6.76437 5.97559 6.70142 6.08315C6.67039 6.13616 6.64836 6.17866 6.63519 6.20535C6.62863 6.21864 6.62436 6.22781 6.62236 6.2322L6.62131 6.23451C6.45182 6.62677 5.99714 6.80909 5.60328 6.64178C5.20792 6.47383 5.02356 6.01718 5.19151 5.62182L5.19202 5.62061L5.1926 5.61925L5.19395 5.61611L5.19743 5.60816L5.20748 5.58576C5.21557 5.56805 5.22649 5.54483 5.24028 5.51688C5.26781 5.4611 5.30717 5.3858 5.35888 5.29743C5.46141 5.12224 5.61741 4.88734 5.83281 4.64964ZM8.6912 11.8032C8.6912 12.1847 8.38167 12.4941 7.99984 12.4941C7.61802 12.4941 7.30849 12.1847 7.30849 11.8032C7.30849 11.4216 7.61802 11.1123 7.99984 11.1123C8.38167 11.1123 8.6912 11.4216 8.6912 11.8032Z"
      fill="#3D4A5D"
    />
  </svg>
</template>

<script>
export default {
  name: "QuestionIcon",
};
</script>
