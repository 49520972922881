<template>
  <ModalWrapper @close="$emit('close')">
    <div class="content two-button-modal">
      <div class="font1-24-30 content__title">Причина</div>

      <div v-if="description.length" class="font2-16-20 content__description">
        {{ description }}
      </div>

      <div class="font2-16-20 content__period">
        Срок блокировки:&nbsp;{{ period }}
      </div>

      <MainButton
        title="Понятно"
        class="content__button"
        @button-clicked="$emit('close')"
      />
    </div>
  </ModalWrapper>
</template>

<script>
import ModalWrapper from "@/components/UI/ModalWrapper";
import MainButton from "@/components/UI/controls/buttons/MainButton";
export default {
  name: "BlacklistModal",
  components: {
    ModalWrapper,
    MainButton,
  },
  props: {
    description: {
      type: String,
      default: "",
    },
    period: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 440px;
  @media (max-width: 600px) {
    width: 304px;
  }
  &__title {
    margin-bottom: 14px;
    font-weight: 500;
    color: $mainBlack;
  }
  &__description {
    margin-bottom: 12px;
    font-weight: 500;
    color: $textSecondary;
  }
  &__period {
    margin-bottom: 32px;
    font-weight: 700;
    color: $mainBlack;
  }

  &__button.el-button.is-plain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    border-radius: 40px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}
@media (max-width: 450px) {
  .content {
    width: 305px;
    &__title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
    }
    &__description {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
    }
    &__period {
      margin-bottom: 16px;
    }
  }
}
</style>
