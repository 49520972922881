<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.9181 2.79724C9.11807 2.73597 6.73278 3.23998 3.37915 5.82345L3.37809 4.39928C3.37776 3.95195 2.99421 3.58958 2.52142 3.58989C2.04862 3.59021 1.66562 3.9531 1.66595 4.40042L1.66784 6.93104C1.6685 7.82482 2.43413 8.5492 3.37878 8.54982L6.04356 8.55158C6.51635 8.55189 6.89989 8.18951 6.90022 7.74218C6.90055 7.29485 6.51754 6.93197 6.04475 6.93165L4.67711 6.93076C7.6263 4.72949 9.56092 4.41868 10.8614 4.46295C11.9682 4.50062 12.8753 4.62975 13.7733 5.01042C14.6722 5.39147 15.622 6.05066 16.7606 7.23542C17.0795 7.56727 17.607 7.57777 17.9388 7.25887C18.2707 6.93997 18.2812 6.41243 17.9623 6.08059C16.7166 4.78433 15.5867 3.96892 14.4238 3.47594C13.26 2.98258 12.1234 2.83827 10.9181 2.79724ZM9.08197 17.7909C10.882 17.8521 13.2673 17.3481 16.6209 14.7646L16.622 16.1888C16.6223 16.6361 17.0058 16.9985 17.4786 16.9982C17.9514 16.9979 18.3344 16.635 18.3341 16.1877L18.3322 13.6571C18.3316 12.7633 17.5659 12.0389 16.6213 12.0383L13.9565 12.0365C13.4837 12.0362 13.1002 12.3986 13.0998 12.8459C13.0995 13.2932 13.4825 13.6561 13.9553 13.6564L15.3229 13.6573C12.3738 15.8586 10.4391 16.1694 9.13867 16.1252C8.03186 16.0875 7.12473 15.9583 6.22676 15.5777C5.3279 15.1966 4.37803 14.5374 3.23949 13.3527C2.92059 13.0208 2.39306 13.0103 2.06121 13.3292C1.72937 13.6481 1.71887 14.1757 2.03777 14.5075C3.28345 15.8038 4.41336 16.6192 5.57626 17.1122C6.74005 17.6055 7.87666 17.7498 9.08197 17.7909Z"
      fill="#A7ADB6"
    />
  </svg>
</template>

<script>
export default {
  name: "RefreshIcon",
};
</script>
