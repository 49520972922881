<template>
  <div :class="['truckCard', { active: isActive }]">
    <div class="truckCard__title">
      <div class="font1-20-24 truckCard__title-number">{{ truck.number }}</div>
      <GlonassStatus :title="truck.glonass_status" :styles="status" />
    </div>

    <div class="font2-12-16 truckCard__subtitle">Последние данные получены</div>

    <div v-if="!isConnected" class="font2-14-18 truckCard__error">
      <RedAlertIcon />
      <span>От ГЛОНАСС не поступают данные</span>
    </div>

    <TruckGlonassInfo v-else :truck="truck" />

    <div class="truckCard__balance">
      <div class="font2-12-16 truckCard__balance-title">Баланс у оператора</div>
      <div class="font2-14-18 truckCard__balance-value">
        {{ formattedNumberRu(truck.glonass_balance, 2) }}&nbsp;₽
      </div>
    </div>

    <div class="truckCard__buttons" v-if="isConnected || isBlocked">
      <GlonassButton
        v-if="isConnected"
        :text="mapButtonText"
        styles="map"
        @clicked="showOnMap"
      />
      <GlonassButton
        v-if="isBlocked"
        text="Машина в черном списке"
        styles="blacklist"
        @clicked="showBlacklist"
      />
    </div>
  </div>
</template>

<script>
import RedAlertIcon from "@/assets/icons/svg/RedAlert.vue";
import TruckGlonassInfo from "@/components/trucks/TruckGlonassInfo.vue";
import GlonassStatus from "./components/GlonassStatus.vue";
import GlonassButton from "@/components/trucks/TruckCard/components/GlonassButton.vue";
import { glonassStatuses } from "./components/glonassData";
import { formattedNumberRu } from "@/core";

export default {
  name: "TruckCard",
  components: { TruckGlonassInfo, RedAlertIcon, GlonassStatus, GlonassButton },
  props: {
    truck: {
      type: Object,
      default: () => ({}),
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      return Object.keys(glonassStatuses).find(
        (k) => glonassStatuses[k] === this.truck.glonass_status
      );
    },
    isConnected() {
      return this.truck.latitude;
    },
    isBlocked() {
      return this.truck.is_blocked;
    },
    mapButtonText() {
      return this.isMobile ? "Показать на карте" : "Выделить на карте";
    },
  },
  methods: {
    formattedNumberRu,
    showOnMap() {
      this.$emit("highlight");
    },
    showBlacklist() {
      this.$emit("blacklist");
    },
  },
};
</script>

<style lang="scss" scoped>
.truckCard {
  padding: 16px;
  background: #fff;
  border: 1px solid $backgroundGray;
  border-radius: 16px;
  &.active {
    border: 1px solid $special;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &-number {
      font-weight: 500;
      color: $mainBlack;
    }
  }
  &__subtitle {
    margin-bottom: 6px;
    font-weight: 500;
    color: $greyText;
  }
  &__error {
    display: flex;
    align-items: center;
    gap: 2px;
    font-weight: 500;
    color: $error;
  }
  &__balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $backgroundGray;
    &-title {
      font-weight: 500;
      color: $textSecondary;
    }
    &-value {
      font-weight: 700;
      color: $mainBlack;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
  }
}
</style>
